// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-dynamic-pages-blog-category-page-js": () => import("./../../../src/components/DynamicPages/BlogCategoryPage.js" /* webpackChunkName: "component---src-components-dynamic-pages-blog-category-page-js" */),
  "component---src-components-dynamic-pages-blog-detail-page-js": () => import("./../../../src/components/DynamicPages/BlogDetailPage.js" /* webpackChunkName: "component---src-components-dynamic-pages-blog-detail-page-js" */),
  "component---src-components-dynamic-pages-life-at-company-dynamic-js": () => import("./../../../src/components/DynamicPages/LifeAtCompanyDynamic.js" /* webpackChunkName: "component---src-components-dynamic-pages-life-at-company-dynamic-js" */),
  "component---src-components-dynamic-pages-project-detail-page-js": () => import("./../../../src/components/DynamicPages/ProjectDetailPage.js" /* webpackChunkName: "component---src-components-dynamic-pages-project-detail-page-js" */),
  "component---src-components-dynamic-pages-service-detail-page-js": () => import("./../../../src/components/DynamicPages/ServiceDetailPage.js" /* webpackChunkName: "component---src-components-dynamic-pages-service-detail-page-js" */),
  "component---src-pages-about-careers-js": () => import("./../../../src/pages/about/careers.js" /* webpackChunkName: "component---src-pages-about-careers-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cyber-security-js": () => import("./../../../src/pages/cyber-security.js" /* webpackChunkName: "component---src-pages-cyber-security-js" */),
  "component---src-pages-hire-a-team-js": () => import("./../../../src/pages/hire-a-team.js" /* webpackChunkName: "component---src-pages-hire-a-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-srashtasoft-js": () => import("./../../../src/pages/life-at-srashtasoft.js" /* webpackChunkName: "component---src-pages-life-at-srashtasoft-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

